define("discourse/plugins/chat/discourse/services/chat-channel-composer", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "discourse/lib/body-scroll-lock"], function (_exports, _tracking, _object, _runloop, _service, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatChannelComposer extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "threadComposer", [(0, _service.service)("chat-thread-composer")]))();
    #threadComposer = (() => (dt7948.i(this, "threadComposer"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "loadingSlider", [_service.service]))();
    #loadingSlider = (() => (dt7948.i(this, "loadingSlider"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "textarea", [_tracking.tracked]))();
    #textarea = (() => (dt7948.i(this, "textarea"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "scroller", [_tracking.tracked]))();
    #scroller = (() => (dt7948.i(this, "scroller"), void 0))();
    init() {
      super.init(...arguments);
      this.appEvents.on("discourse:focus-changed", this, this.blur);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("discourse:focus-changed", this, this.blur);
    }
    focus() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.textarea?.focus(options);
      (0, _runloop.schedule)("afterRender", () => {
        if (this.capabilities.isIOS && !this.capabilities.isIpadOS) {
          (0, _bodyScrollLock.disableBodyScroll)(this.scroller, {
            reverse: true
          });
        }
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "focus", [_object.action]))();
    blur() {
      this.textarea?.blur();
    }
    static #_13 = (() => dt7948.n(this.prototype, "blur", [_object.action]))();
    edit(message) {
      this.chat.activeMessage = null;
      message.editing = true;
      message.channel.draft = message;
      if (this.site.desktopView) {
        this.focus({
          refreshHeight: true,
          ensureAtEnd: true
        });
      }
    }
    static #_14 = (() => dt7948.n(this.prototype, "edit", [_object.action]))();
    async replyTo(message) {
      this.chat.activeMessage = null;
      if (message.channel.threadingEnabled) {
        if (!message.thread?.id) {
          try {
            this.loadingSlider.transitionStarted();
            const threadObject = await this.chatApi.createThread(message.channel.id, message.id);
            message.thread = message.channel.threadsManager.add(message.channel, threadObject);
          } finally {
            this.loadingSlider.transitionEnded();
          }
        }
        message.channel.resetDraft(this.currentUser);
        await this.router.transitionTo("chat.channel.thread", ...message.thread.routeModels);
        this.threadComposer.focus({
          ensureAtEnd: true,
          refreshHeight: true
        });
      } else {
        message.channel.draft.inReplyTo = message;
        this.focus({
          ensureAtEnd: true,
          refreshHeight: true
        });
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "replyTo", [_object.action]))();
  }
  _exports.default = ChatChannelComposer;
});