define("discourse/plugins/chat/discourse/controllers/admin-plugins-chat", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _controller, _object, _computed, _service, _ajax, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    queryParams = (() => [{
      selectedWebhookId: "id"
    }])();
    loading = false;
    creatingNew = false;
    newWebhookName = "";
    newWebhookChannelId = null;
    emojiPickerIsActive = false;
    static #_2 = (() => dt7948.g(this.prototype, "nameAndChannelValid", [(0, _computed.and)("newWebhookName", "newWebhookChannelId")]))();
    #nameAndChannelValid = (() => (dt7948.i(this, "nameAndChannelValid"), void 0))();
    get sortedWebhooks() {
      return this.model.incoming_chat_webhooks?.sortBy("updated_at").reverse() || [];
    }
    static #_3 = (() => dt7948.n(this.prototype, "sortedWebhooks", [(0, _object.computed)("model.incoming_chat_webhooks.@each.updated_at")]))();
    get selectedWebhook() {
      if (!this.selectedWebhookId) {
        return;
      }
      const id = parseInt(this.selectedWebhookId, 10);
      return this.model.incoming_chat_webhooks.findBy("id", id);
    }
    static #_4 = (() => dt7948.n(this.prototype, "selectedWebhook", [(0, _object.computed)("selectedWebhookId")]))();
    get saveEditDisabled() {
      return !this.selectedWebhook.name || !this.selectedWebhook.chat_channel.id;
    }
    static #_5 = (() => dt7948.n(this.prototype, "saveEditDisabled", [(0, _object.computed)("selectedWebhook.name", "selectedWebhook.chat_channel.id")]))();
    createNewWebhook() {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      const data = {
        name: this.newWebhookName,
        chat_channel_id: this.newWebhookChannelId
      };
      return (0, _ajax.ajax)("/admin/plugins/chat/hooks", {
        data,
        type: "POST"
      }).then(webhook => {
        const newWebhook = _object.default.create(webhook);
        this.set("model.incoming_chat_webhooks", [newWebhook].concat(this.model.incoming_chat_webhooks));
        this.resetNewWebhook();
        this.setProperties({
          loading: false,
          selectedWebhookId: newWebhook.id
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_6 = (() => dt7948.n(this.prototype, "createNewWebhook", [_object.action]))();
    resetNewWebhook() {
      this.setProperties({
        creatingNew: false,
        newWebhookName: "",
        newWebhookChannelId: null
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "resetNewWebhook", [_object.action]))();
    destroyWebhook(webhook) {
      this.dialog.deleteConfirm({
        message: _discourseI18n.default.t("chat.incoming_webhooks.confirm_destroy"),
        didConfirm: () => {
          this.set("loading", true);
          return (0, _ajax.ajax)(`/admin/plugins/chat/hooks/${webhook.id}`, {
            type: "DELETE"
          }).then(() => {
            this.model.incoming_chat_webhooks.removeObject(webhook);
            this.set("loading", false);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "destroyWebhook", [_object.action]))();
    emojiSelected(emoji) {
      this.selectedWebhook.set("emoji", `:${emoji}:`);
      return this.set("emojiPickerIsActive", false);
    }
    static #_9 = (() => dt7948.n(this.prototype, "emojiSelected", [_object.action]))();
    saveEdit() {
      this.set("loading", true);
      const data = {
        name: this.selectedWebhook.name,
        chat_channel_id: this.selectedWebhook.chat_channel.id,
        description: this.selectedWebhook.description,
        emoji: this.selectedWebhook.emoji,
        username: this.selectedWebhook.username
      };
      return (0, _ajax.ajax)(`/admin/plugins/chat/hooks/${this.selectedWebhook.id}`, {
        data,
        type: "PUT"
      }).then(() => {
        this.selectedWebhook.set("updated_at", new Date());
        this.setProperties({
          loading: false,
          selectedWebhookId: null
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_10 = (() => dt7948.n(this.prototype, "saveEdit", [_object.action]))();
    changeChatChannel(chatChannelId) {
      this.selectedWebhook.set("chat_channel", this.model.chat_channels.findBy("id", chatChannelId));
    }
    static #_11 = (() => dt7948.n(this.prototype, "changeChatChannel", [_object.action]))();
  }
  _exports.default = AdminPluginsChatController;
});