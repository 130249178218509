define("discourse/plugins/chat/discourse/lib/textarea-interactor", ["exports", "@ember/application", "@ember/destroyable", "@ember/object", "@ember/runloop", "@ember/service", "discourse/mixins/textarea-text-manipulation"], function (_exports, _application, _destroyable, _object, _runloop, _service, _textareaTextManipulation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This class sole purpose is to provide a way to interact with the textarea
  // using the existing TextareaTextManipulation mixin without using it directly
  // in the composer component. It will make future migration easier.
  class TextareaInteractor extends _object.default.extend(_textareaTextManipulation.default) {
    static #_ = (() => dt7948.g(this.prototype, "capabilities", [_service.service]))();
    #capabilities = (() => (dt7948.i(this, "capabilities"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor(owner, textarea) {
      super(...arguments);
      (0, _application.setOwner)(this, owner);
      this.textarea = textarea;
      this._textarea = textarea;
      this.element = this._textarea;
      this.ready = true;
      this.composerFocusSelector = `#${textarea.id}`;
      this.init(); // mixin init wouldn't be called otherwise
      this.composerEventPrefix = null; // we don't need app events

      // paste is using old native ember events defined on composer
      this.textarea.addEventListener("paste", this.paste);
      (0, _destroyable.registerDestructor)(this, instance => instance.teardown());
    }
    teardown() {
      this.textarea.removeEventListener("paste", this.paste);
    }
    set value(value) {
      this._textarea.value = value;
      const event = new Event("input", {
        bubbles: true,
        cancelable: true
      });
      this._textarea.dispatchEvent(event);
    }
    blur() {
      (0, _runloop.next)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          this._textarea.blur();
        });
      });
    }
    focus() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        ensureAtEnd: false,
        refreshHeight: true,
        addText: null
      };
      (0, _runloop.next)(() => {
        (0, _runloop.schedule)("afterRender", () => {
          if (opts.refreshHeight) {
            this.refreshHeight();
          }
          if (opts.ensureAtEnd) {
            this.ensureCaretAtEnd();
          }
          if (this.capabilities.isIpadOS || this.site.mobileView) {
            return;
          }
          if (opts.addText) {
            this.addText(this.getSelected(), opts.addText);
          }
          this.focusTextArea();
        });
      });
    }
    ensureCaretAtEnd() {
      (0, _runloop.schedule)("afterRender", () => {
        this._textarea.setSelectionRange(this._textarea.value.length, this._textarea.value.length);
      });
    }
    refreshHeight() {
      (0, _runloop.schedule)("afterRender", () => {
        // this is a quirk which forces us to `auto` first or textarea
        // won't resize
        this._textarea.style.height = "auto";

        // +1 is to workaround a rounding error visible on electron
        // causing scrollbars to show when they shouldn’t
        this._textarea.style.height = this._textarea.scrollHeight + 1 + "px";
      });
    }
  }
  _exports.default = TextareaInteractor;
});