define("discourse/plugins/chat/discourse/lib/chat-message-flag", ["exports", "@ember/application", "@ember/service", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-i18n"], function (_exports, _application, _service, _ajaxError, _getUrl, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessageFlag {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    constructor(owner) {
      (0, _application.setOwner)(this, owner);
    }
    title() {
      return "flagging.title";
    }
    customSubmitLabel() {
      return "flagging.notify_action";
    }
    submitLabel() {
      return "chat.flagging.action";
    }
    targetsTopic() {
      return false;
    }
    editable() {
      return false;
    }
    _rewriteFlagDescriptions(flags) {
      return flags.map(flag => {
        flag.set("description", _discourseI18n.default.t(`chat.flags.${flag.name_key}`, {
          basePath: (0, _getUrl.default)(""),
          defaultValue: ""
        }));
        return flag;
      });
    }
    flagsAvailable(flagModal) {
      let flagsAvailable = flagModal.site.flagTypes;
      flagsAvailable = flagsAvailable.filter(flag => {
        return flagModal.args.model.flagModel.availableFlags.includes(flag.name_key);
      });

      // "message user" option should be at the top
      const notifyUserIndex = flagsAvailable.indexOf(flagsAvailable.filterBy("name_key", "notify_user")[0]);
      if (notifyUserIndex !== -1) {
        const notifyUser = flagsAvailable[notifyUserIndex];
        flagsAvailable.splice(notifyUserIndex, 1);
        flagsAvailable.splice(0, 0, notifyUser);
      }
      return this._rewriteFlagDescriptions(flagsAvailable);
    }
    async create(flagModal, opts) {
      flagModal.args.closeModal();
      const channelId = flagModal.args.model.flagModel.channel.id;
      const messageId = flagModal.args.model.flagModel.id;
      try {
        await this.chatApi.flagMessage(channelId, messageId, {
          flag_type_id: flagModal.selected.id,
          message: opts.message,
          is_warning: opts.isWarning,
          take_action: opts.takeAction,
          queue_for_review: opts.queue_for_review
        });
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
  }
  _exports.default = ChatMessageFlag;
});