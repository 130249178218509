define("discourse/plugins/chat/discourse/components/reviewable-chat-message", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/routing", "@ember/service", "@ember/template", "truth-helpers", "discourse/components/reviewable-created-by", "discourse/components/reviewable-post-header", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _routing, _service, _template2, _truthHelpers, _reviewableCreatedBy, _reviewablePostHeader, _i18n, _channelTitle, _chatChannel, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReviewableChatMessage extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    get channel() {
      if (!this.args.reviewable.chat_channel) {
        return;
      }
      return _chatChannel.default.create(this.args.reviewable.chat_channel);
    }
    static #_3 = (() => dt7948.n(this.prototype, "channel", [_tracking.cached]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.channel}}
          <div class="flagged-post-header">
            <LinkTo
              @route="chat.channel.near-message"
              @models={{array
                this.channel.slugifiedTitle
                this.channel.id
                @reviewable.target_id
              }}
            >
              <ChannelTitle @channel={{this.channel}} />
            </LinkTo>
          </div>
        {{/if}}
    
        <div class="post-contents-wrapper">
          <ReviewableCreatedBy
            @user={{@reviewable.target_created_by}}
            @tagName=""
          />
          <div class="post-contents">
            <ReviewablePostHeader
              @reviewable={{@reviewable}}
              @createdBy={{@reviewable.target_created_by}}
              @tagName=""
            />
    
            <div class="post-body">
              {{htmlSafe
                (or @reviewable.payload.message_cooked @reviewable.cooked)
              }}
            </div>
    
            {{#if @reviewable.payload.transcript_topic_id}}
              <div class="transcript">
                <LinkTo
                  @route="topic"
                  @models={{array "-" @reviewable.payload.transcript_topic_id}}
                  class="btn btn-small"
                >
                  {{i18n "review.transcript.view"}}
                </LinkTo>
              </div>
            {{/if}}
    
            {{yield}}
          </div>
        </div>
      
    */
    {
      "id": "ivj7Nzci",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"channel\"]],[[[1,\"      \"],[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@route\",\"@models\"],[\"chat.channel.near-message\",[28,[32,1],[[30,0,[\"channel\",\"slugifiedTitle\"]],[30,0,[\"channel\",\"id\"]],[30,1,[\"target_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],null,[[\"@channel\"],[[30,0,[\"channel\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n      \"],[8,[32,3],null,[[\"@user\",\"@tagName\"],[[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,1],[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n          \"],[1,[28,[32,5],[[28,[32,6],[[30,1,[\"payload\",\"message_cooked\"]],[30,1,[\"cooked\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"payload\",\"transcript_topic_id\"]],[[[1,\"          \"],[10,0],[14,0,\"transcript\"],[12],[1,\"\\n            \"],[8,[32,0],[[24,0,\"btn btn-small\"]],[[\"@route\",\"@models\"],[\"topic\",[28,[32,1],[\"-\",[30,1,[\"payload\",\"transcript_topic_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[32,7],[\"review.transcript.view\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@reviewable\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/reviewable-chat-message.js",
      "scope": () => [_routing.LinkTo, _helper.array, _channelTitle.default, _reviewableCreatedBy.default, _reviewablePostHeader.default, _template2.htmlSafe, _truthHelpers.or, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ReviewableChatMessage;
});