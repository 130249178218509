define("discourse/plugins/chat/discourse/lib/chat-messages-manager", ["exports", "@glimmer/tracking", "@ember/application"], function (_exports, _tracking, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessagesManager {
    static #_ = (() => dt7948.g(this.prototype, "messages", [_tracking.tracked], function () {
      return [];
    }))();
    #messages = (() => (dt7948.i(this, "messages"), void 0))();
    constructor(owner) {
      (0, _application.setOwner)(this, owner);
    }
    get stagedMessages() {
      return this.messages.filterBy("staged");
    }
    static #_2 = (() => dt7948.n(this.prototype, "stagedMessages", [_tracking.cached]))();
    get selectedMessages() {
      return this.messages.filterBy("selected");
    }
    static #_3 = (() => dt7948.n(this.prototype, "selectedMessages", [_tracking.cached]))();
    clearSelectedMessages() {
      this.selectedMessages.forEach(message => message.selected = false);
    }
    clear() {
      this.messages = [];
    }
    addMessages() {
      let messages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.messages = this.messages.concat(messages).uniqBy("id").sort((a, b) => a.createdAt - b.createdAt);
    }
    findMessage(messageId) {
      return this.messages.find(message => message.id === parseInt(messageId, 10));
    }
    findFirstMessageOfDay(a) {
      return this.messages.find(b => a.getFullYear() === b.createdAt.getFullYear() && a.getMonth() === b.createdAt.getMonth() && a.getDate() === b.createdAt.getDate());
    }
    removeMessage(message) {
      return this.messages.removeObject(message);
    }
    findStagedMessage(stagedMessageId) {
      return this.stagedMessages.find(message => message.id === stagedMessageId);
    }
    findIndexOfMessage(id) {
      return this.messages.findIndex(m => m.id === id);
    }
    findLastMessage() {
      return this.messages.findLast(message => !message.deletedAt);
    }
    findLastUserMessage(user) {
      return this.messages.findLast(message => message.user.id === user.id && !message.deletedAt);
    }
  }
  _exports.default = ChatMessagesManager;
});