define("discourse/plugins/chat/discourse/components/chat-thread-title-prompt", ["exports", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/service", "I18n", "discourse/plugins/chat/discourse/components/chat/modal/thread-settings", "discourse/plugins/chat/discourse/lib/chat-constants", "discourse/plugins/chat/discourse/models/user-chat-thread-membership"], function (_exports, _component, _object, _runloop, _service, _I18n, _threadSettings, _chatConstants, _userChatThreadMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadTitlePrompt extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    toastText = (() => ({
      title: _I18n.default.t("chat.thread_title_toast.title"),
      message: _I18n.default.t("chat.thread_title_toast.message"),
      dismissLabel: _I18n.default.t("chat.thread_title_toast.dismiss_action"),
      primaryLabel: _I18n.default.t("chat.thread_title_toast.primary_action")
    }))();
    constructor() {
      super(...arguments);
      (0, _runloop.next)(() => {
        if (this.canShowToast) {
          this.show();
          this.updateThreadTitlePrompt();
        }
      });
    }
    get membership() {
      return this.args.thread.currentUserMembership;
    }
    async updateThreadTitlePrompt() {
      try {
        const result = await this.chatApi.updateCurrentUserThreadTitlePrompt(this.args.thread.channel.id, this.args.thread.id);
        this.args.thread.currentUserMembership = _userChatThreadMembership.default.create(result.membership);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Couldn't save thread title prompt status", e);
        if (this.membership) {
          this.membership.threadTitlePromptSeen = false;
        }
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "updateThreadTitlePrompt", [_object.action]))();
    disableFutureThreadTitlePrompts() {
      this.currentUser.set("user_option.show_thread_title_prompts", false);
      this.currentUser.save();
    }
    static #_7 = (() => dt7948.n(this.prototype, "disableFutureThreadTitlePrompts", [_object.action]))();
    get canShowToast() {
      if (this.site.desktopView || this.args.thread.originalMessage?.user?.id !== this.currentUser.id && !this.currentUser.admin) {
        return false;
      }
      const titleNotSet = this.args.thread.title === null;
      const hasReplies = this.args.thread.replyCount >= _chatConstants.THREAD_TITLE_PROMPT_THRESHOLD;
      const showPrompts = this.currentUser.user_option.show_thread_title_prompts;
      const promptNotSeen = !this.membership?.threadTitlePromptSeen;
      return titleNotSet && hasReplies && showPrompts && promptNotSeen;
    }
    show() {
      this.toasts.default({
        duration: 5000,
        showProgressBar: true,
        class: "thread-toast",
        data: {
          title: this.toastText.title,
          message: this.toastText.message,
          actions: [{
            label: this.toastText.dismissLabel,
            class: "btn-link toast-hide",
            action: toast => {
              this.disableFutureThreadTitlePrompts();
              toast.close();
            }
          }, {
            label: this.toastText.primaryLabel,
            class: "btn-primary toast-action",
            action: toast => {
              this.modal.show(_threadSettings.default, {
                model: this.args.thread
              });
              toast.close();
            }
          }]
        }
      });
    }
  }
  _exports.default = ChatThreadTitlePrompt;
});