define("discourse/plugins/chat/discourse/services/chat-tracking-state-manager", ["exports", "@ember/application", "@ember/runloop", "@ember/service", "discourse-common/lib/debounce", "discourse/plugins/chat/discourse/models/chat-tracking-state"], function (_exports, _application, _runloop, _service, _debounce, _chatTrackingState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This service is used to provide a global interface to tracking individual
   * channels and threads. In many places in the app, we need to know the global
   * unread count for channels, threads, etc.
   *
   * The individual tracking state of each channel and thread is stored in
   * a ChatTrackingState class instance and changed via the getters/setters
   * provided there.
   *
   * This service is also used to preload bulk tracking state for channels
   * and threads, which is used when the user first loads the app, and in
   * certain cases where we need to set the state for many items at once.
   */
  class ChatTrackingStateManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    // NOTE: In future, we may want to preload some thread tracking state
    // as well, but for now we do that on demand when the user opens a channel,
    // to avoid having to load all the threads across all channels into memory at once.
    setupWithPreloadedState(_ref) {
      let {
        channel_tracking = {}
      } = _ref;
      this.chatChannelsManager.channels.forEach(channel => {
        if (channel_tracking[channel.id.toString()]) {
          this.#setState(channel, channel_tracking[channel.id.toString()]);
        }
      });
    }
    setupChannelThreadState(channel, threadTracking) {
      channel.threadsManager.threads.forEach(thread => {
        const tracking = threadTracking[thread.id.toString()];
        if (tracking) {
          this.#setState(thread, tracking);
        }
      });
    }
    get publicChannelUnreadCount() {
      return this.#publicChannels.reduce((unreadCount, channel) => {
        return unreadCount + channel.tracking.unreadCount;
      }, 0);
    }
    get directMessageUnreadCount() {
      return this.#directMessageChannels.reduce((unreadCount, channel) => {
        return unreadCount + channel.tracking.unreadCount;
      }, 0);
    }
    get publicChannelMentionCount() {
      return this.#publicChannels.reduce((mentionCount, channel) => {
        return mentionCount + channel.tracking.mentionCount;
      }, 0);
    }
    get directMessageMentionCount() {
      return this.#directMessageChannels.reduce((dmMentionCount, channel) => {
        return dmMentionCount + channel.tracking.mentionCount;
      }, 0);
    }
    get allChannelMentionCount() {
      return this.publicChannelMentionCount + this.directMessageMentionCount;
    }
    get allChannelUrgentCount() {
      return this.publicChannelMentionCount + this.directMessageUnreadCount;
    }
    get hasUnreadThreads() {
      return this.#publicChannels.some(channel => channel.unreadThreadsCount > 0);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      (0, _runloop.cancel)(this._onTriggerNotificationDebounceHandler);
    }

    /**
     * Some reactivity in the app such as the document title
     * updates are only done via appEvents -- rather than
     * sprinkle this appEvent call everywhere we just define
     * it here so it can be changed as required.
     */
    triggerNotificationsChanged() {
      this._onTriggerNotificationDebounceHandler = (0, _debounce.default)(this, this.#triggerNotificationsChanged, 100);
    }
    #triggerNotificationsChanged() {
      this.appEvents.trigger("notifications:changed");
    }
    #setState(model, state) {
      if (!model.tracking) {
        model.tracking = new _chatTrackingState.default((0, _application.getOwner)(this));
      }
      model.tracking.unreadCount = state.unread_count;
      model.tracking.mentionCount = state.mention_count;
    }
    get #publicChannels() {
      return this.chatChannelsManager.publicMessageChannels;
    }
    get #directMessageChannels() {
      return this.chatChannelsManager.directMessageChannels;
    }
  }
  _exports.default = ChatTrackingStateManager;
});