define("discourse/plugins/chat/discourse/services/chat-side-panel-size", ["exports", "@ember/service", "discourse/lib/key-value-store"], function (_exports, _service, _keyValueStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatSidePanelSize extends _service.default {
    STORE_NAMESPACE = "discourse_chat_side_panel_size_";
    MIN_WIDTH = 250;
    store = (() => new _keyValueStore.default(this.STORE_NAMESPACE))();
    get width() {
      return this.store.getObject("width") || this.MIN_WIDTH;
    }
    set width(width) {
      this.store.setObject({
        key: "width",
        value: this.#min(width, this.MIN_WIDTH)
      });
    }
    #min(number, min) {
      return Math.max(number, min);
    }
  }
  _exports.default = ChatSidePanelSize;
});