define("discourse/plugins/chat/discourse/lib/chat-messages-loader", ["exports", "@glimmer/tracking", "@ember/application", "@ember/service", "discourse/lib/ajax-error", "discourse/plugins/chat/discourse/lib/chat-constants", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _tracking, _application, _service, _ajaxError, _chatConstants, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatMessagesLoader {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "canLoadMorePast", [_tracking.tracked], function () {
      return false;
    }))();
    #canLoadMorePast = (() => (dt7948.i(this, "canLoadMorePast"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "canLoadMoreFuture", [_tracking.tracked], function () {
      return false;
    }))();
    #canLoadMoreFuture = (() => (dt7948.i(this, "canLoadMoreFuture"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "fetchedOnce", [_tracking.tracked], function () {
      return false;
    }))();
    #fetchedOnce = (() => (dt7948.i(this, "fetchedOnce"), void 0))();
    constructor(owner, model) {
      (0, _application.setOwner)(this, owner);
      this.model = model;
    }
    get loadedPast() {
      return this.canLoadMorePast === false && this.fetchedOnce;
    }
    async loadMore() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (this.canLoadMoreFuture === false && args.direction === _chatConstants.FUTURE) {
        return;
      }
      if (this.canLoadMorePast === false && args.direction === _chatConstants.PAST) {
        return;
      }
      const nextTargetMessage = this.#computeNextTargetMessage(args.direction, this.model);
      args = {
        direction: args.direction,
        page_size: _chatConstants.DEFAULT_MESSAGE_PAGE_SIZE,
        target_message_id: nextTargetMessage?.id
      };
      args = this.#cleanArgs(args);
      let result;
      try {
        this.loading = true;
        result = await this.#apiFunction(args);
        this.canLoadMoreFuture = result.meta.can_load_more_future;
        this.canLoadMorePast = result.meta.can_load_more_past;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
      return result;
    }
    async load() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.canLoadMorePast = true;
      this.canLoadMoreFuture = true;
      this.fetchedOnce = false;
      this.loading = true;
      args.page_size ??= _chatConstants.DEFAULT_MESSAGE_PAGE_SIZE;
      args = this.#cleanArgs(args);
      let result;
      try {
        result = await this.#apiFunction(args);
        this.canLoadMoreFuture = result.meta.can_load_more_future;
        this.canLoadMorePast = result.meta.can_load_more_past;
        this.fetchedOnce = true;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
      return result;
    }
    #apiFunction() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (this.model instanceof _chatChannel.default) {
        return this.chatApi.channelMessages(this.model.id, args);
      } else {
        return this.chatApi.channelThreadMessages(this.model.channel.id, this.model.id, args);
      }
    }
    #cleanArgs(args) {
      return Object.keys(args).filter(k => args[k] != null).reduce((a, k) => ({
        ...a,
        [k]: args[k]
      }), {});
    }
    #computeNextTargetMessage(direction, model) {
      return direction === _chatConstants.PAST ? model.messagesManager.messages.find(message => !message.staged) : model.messagesManager.messages.findLast(message => !message.staged);
    }
  }
  _exports.default = ChatMessagesLoader;
});