define("discourse/plugins/chat/discourse/components/chat/modal/channel-summary", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/conditional-loading-section", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse-i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _conditionalLoadingSection, _dModal, _dModalCancel, _ajaxError, _i18n, _discourseI18n, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatModalChannelSummary extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "sinceHours", [_tracking.tracked], function () {
      return null;
    }))();
    #sinceHours = (() => (dt7948.i(this, "sinceHours"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "summary", [_tracking.tracked], function () {
      return null;
    }))();
    #summary = (() => (dt7948.i(this, "summary"), void 0))();
    availableSummaries = (() => ({}))();
    sinceOptions = (() => [1, 3, 6, 12, 24, 72, 168].map(hours1 => {
      return {
        name: _discourseI18n.default.t("chat.summarization.since", {
          count: hours1
        }),
        value: hours1
      };
    }))();
    get channelId() {
      return this.args.model.channelId;
    }
    summarize(since1) {
      this.sinceHours = since1;
      this.loading = true;
      if (this.availableSummaries[since1]) {
        this.summary = this.availableSummaries[since1];
        this.loading = false;
        return;
      }
      return this.chatApi.summarize(this.channelId, {
        since: since1
      }).then(data1 => {
        this.availableSummaries[this.sinceHours] = data1.summary;
        this.summary = this.availableSummaries[this.sinceHours];
      }).catch(_ajaxError.popupAjaxError).finally(() => this.loading = false);
    }
    static #_5 = (() => dt7948.n(this.prototype, "summarize", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="chat-modal-channel-summary"
          @title={{i18n "chat.summarization.title"}}
        >
          <:body>
            <span>{{i18n "chat.summarization.description"}}</span>
            <ComboBox
              @value={{this.sinceHours}}
              @content={{this.sinceOptions}}
              @onChange={{this.summarize}}
              @valueProperty="value"
              class="summarization-since"
            />
            <ConditionalLoadingSection @isLoading={{this.loading}}>
              <p class="summary-area">{{this.summary}}</p>
            </ConditionalLoadingSection>
          </:body>
          <:footer>
            <DModalCancel @close={{@closeModal}} />
          </:footer>
        </DModal>
      
    */
    {
      "id": "Wd6UNPWs",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-modal-channel-summary\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[32,1],[\"chat.summarization.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,1],[12],[1,[28,[32,1],[\"chat.summarization.description\"],null]],[13],[1,\"\\n        \"],[8,[32,2],[[24,0,\"summarization-since\"]],[[\"@value\",\"@content\",\"@onChange\",\"@valueProperty\"],[[30,0,[\"sinceHours\"]],[30,0,[\"sinceOptions\"]],[30,0,[\"summarize\"]],\"value\"]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@isLoading\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,2],[14,0,\"summary-area\"],[12],[1,[30,0,[\"summary\"]]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/modal/channel-summary.js",
      "scope": () => [_dModal.default, _i18n.default, _comboBox.default, _conditionalLoadingSection.default, _dModalCancel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatModalChannelSummary;
});